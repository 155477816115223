<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import DeleteDialog from '@/components/DeleteDialog';
import AntInput from '@/components/AntInput.vue';
import { mapActions, mapGetters } from 'vuex';

const namespace = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeRisks',
  components: { AntInput, DeleteDialog, DynamicDataTable },
  data: () => ({
    riskHeaders: [
      { text: 'Titel', value: 'title' },
      { text: 'Beschrijving', value: 'description' },
      { text: 'Kans', value: 'chance' },
      { text: 'Gevolg', value: 'consequence' },
      { text: 'Risico', value: 'risk' },
      { text: 'Acties', value: 'actions', align: 'right', sortable: false },
    ],
    riskDialog: false,
    riskItem: {
      id: null,
      title: '',
      description: '',
      chance: null,
      consequence: null,
    },
    rules: {
      required: (value) => !!value || 'Required.',
      maxLength: (value) =>
        (value && value.length < 250) || 'Max 250 characters',
    },
    deletableRisk: null,
  }),
  computed: { ...mapGetters(namespace, ['risks', 'isLoading']) },
  async mounted() {
    await this.loadRisks();
  },
  methods: {
    ...mapActions(namespace, [
      'loadTablesIfNotExists',
      'selectProject',
      'loadRisks',
      'storeRisk',
      'removeRisk',
    ]),
    closeRiskDialog() {
      this.riskDialog = false;
    },
    async saveRisk() {
      if (this.$refs.form.validate()) {
        this.riskDialog = false;
        await this.storeRisk({ ...this.riskItem });
        this.riskItem = {
          id: null,
          title: '',
          description: '',
          chance: null,
          consequence: null,
        };
        this.$refs.form.reset();
      }
    },
    editRisk(risk) {
      this.riskItem = risk;
      this.riskDialog = true;
    },
    deleteRisk() {
      this.removeRisk({ ...this.deletableRisk });
      this.deletableRisk = null;
    },
  },
};
</script>

<template>
  <div class="full-width">
    <dynamic-data-table
      :table-headers="riskHeaders"
      :table-records="risks"
      can-delete
      can-edit
      class="background-white"
      table-title="Risico's"
      @deleteItem="(risk) => (deletableRisk = risk)"
      @editItem="editRisk"
    >
      <template #table-actions>
        <v-dialog
          key="add-edit-project"
          v-model="riskDialog"
          max-width="500px"
          @click:outside="closeRiskDialog"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon class="ant-icon" dense v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>Nieuw risico</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              Voeg risico toe
            </v-card-title>
            <v-divider />
            <v-form ref="form" lazy-validation>
              <div class="px-10 pb-5">
                <div class="d-flex flex-column">
                  <ant-input :is-optional="false" class="flex" label="Titel">
                    <template #input-field>
                      <v-text-field
                        v-model="riskItem.title"
                        :disabled="isLoading"
                        :rules="[rules.required, rules.maxLength]"
                        counter="250"
                        dense
                        filled
                        hide-details
                        maxlength="250"
                        placeholder="Titel"
                        required
                        single-line
                      />
                    </template>
                  </ant-input>
                  <ant-input is-optional label="Beschrijving">
                    <template #input-field>
                      <v-text-field
                        v-model="riskItem.description"
                        :disabled="isLoading"
                        dense
                        filled
                        hide-details
                        placeholder="beschrijving"
                        single-line
                      />
                    </template>
                  </ant-input>
                </div>
                <v-row>
                  <v-col>
                    <ant-input class="flex" is-optional label="Kans">
                      <template #input-field>
                        <v-select
                          v-model="riskItem.chance"
                          :disabled="isLoading"
                          :items="[
                            {
                              text: 'Zeer onwaarschijnlijk: De kans dat het risico zich voordoet, is extreem klein.',
                              value: 1,
                            },
                            {
                              text: 'Onwaarschijnlijk: Het risico kan zich voordoen, maar het is niet waarschijnlijk.',
                              value: 2,
                            },
                            {
                              text: 'Mogelijk: Het risico kan optreden; er is een redelijke kans.',
                              value: 3,
                            },
                            {
                              text: 'Waarschijnlijk: Het is aannemelijk dat het risico zich voordoet.',
                              value: 4,
                            },
                            {
                              text: 'Zeer waarschijnlijk: Het risico zal zich vrijwel zeker voordoen.',
                              value: 5,
                            },
                          ]"
                          :rules="[rules.required]"
                          dense
                          filled
                          hide-details
                          item-text="text"
                          item-value="value"
                          placeholder="chance 1-5"
                          required
                          single-line
                          type="number"
                        >
                          <template #item="{ item }">
                            {{ item.value }}<span class="mx-2">-</span
                            ><span class="fs-12">{{ item.text }}</span>
                          </template>
                        </v-select>
                      </template>
                    </ant-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ant-input class="flex" is-optional label="Gevolg">
                      <template #input-field>
                        <v-select
                          v-model="riskItem.consequence"
                          :disabled="isLoading"
                          :items="[
                            {
                              text: 'Verwaarloosbaar: Het gevolg is minimaal en heeft nauwelijks impact.',
                              value: 1,
                            },
                            {
                              text: 'Beperkt: Het gevolg is merkbaar maar beheersbaar; geringe impact.',
                              value: 2,
                            },
                            {
                              text: 'Aanzienlijk: Het gevolg is aanzienlijk en vereist aandacht; matige impact.',
                              value: 3,
                            },
                            {
                              text: 'Ernstig: Het gevolg is ernstig en kan aanzienlijke schade of verstoring veroorzaken.',
                              value: 4,
                            },
                            {
                              text: 'Catastrofaal: Het gevolg is extreem en kan leiden tot zeer ernstige schade of falen.',
                              value: 5,
                            },
                          ]"
                          :rules="[rules.required]"
                          dense
                          filled
                          hide-details
                          item-text="text"
                          item-value="value"
                          placeholder="consequence 1-5"
                          required
                          single-line
                          type="number"
                        >
                          <template #item="{ item }">
                            {{ item.value }}<span class="mx-2">-</span
                            ><span class="fs-12">{{ item.text }}</span>
                          </template>
                        </v-select>
                      </template>
                    </ant-input>
                  </v-col>
                </v-row>
              </div>
            </v-form>
            <v-card-actions class="ant-border-top ant-dialog-actions-bg">
              <v-spacer />
              <v-btn color="error" small text @click="closeRiskDialog">
                Annuleren
              </v-btn>
              <v-btn color="primary" elevation="0" small @click="saveRisk">
                Opslaan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="!!deletableRisk"
      :title="`Weet je zeker dat je dit risico wilt verwijderen: ${deletableRisk?.title}?`"
      @closeDialog="deletableRisk = null"
      @deleteAction="deleteRisk"
    />
  </div>
</template>
